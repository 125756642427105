export default function (tagName: string): GenericCSSProperties {
  if (tagName === 'coffee_talk') {
    return {
      backgroundColor: 'var(--blue-4)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'founder') {
    return {
      backgroundColor: 'var(--red-5)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'faq_tag') {
    return {
      backgroundColor: 'var(--green-5)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'faq_tag_selected') {
    return {
      backgroundColor: 'var(--green-1)',
      color: '#fff',
    }
  } else if (tagName === 'Article') {
    return {
      backgroundColor: 'var(--red-4)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'press_release') {
    return {
      backgroundColor: 'var(--green-4)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'local_requirement') {
    return {
      backgroundColor: 'var(--purple-4)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'event') {
    return {
      backgroundColor: 'var(--yellow-4)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'customer_success_story') {
    return {
      backgroundColor: 'var(--purple-2)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'Video') {
    return {
      backgroundColor: 'var(--green-2)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'job_open_position') {
    return {
      backgroundColor: 'var(--red-3)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'guide') {
    return {
      backgroundColor: 'var(--blue-3)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'ebook') {
    return {
      backgroundColor: 'var(--red-4)',
      color: 'var(--grey-1)',
    }
  } else if (tagName === 'news') {
    return {
      backgroundColor: 'var(--grey-5)',
      color: 'var(--grey-1)',
    }
  } else {
    return {
      backgroundColor: 'var(--grey-1)',
    }
  }
}
